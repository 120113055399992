<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: First Name -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Field Group Name"
            label-for="field-name"
          >
            <b-form-input
              id="field-name"
              v-model="fieldGroup.name"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div style="clear: both" class="mt-2"></div>

      <div class="float-left">
        <h3>Fields</h3>
      </div>

      <div class="float-left">
        <b-button
          variant="primary"
          @click="addField()"
          size="sm"
          class="ml-1"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span>Add</span>
        </b-button>
      </div>

      <div style="clear: both" class="mb-1"></div>

      <b-row v-if="(fieldGroup.fields.length == 0 || fieldGroup.fields === null) & !isLoading">
        <b-col cols="12">
          <div class="text-center">
            <div class="demo-spacing-0">
              <b-alert
                variant="warning"
                show
              >
                <div class="alert-body">
                  The field's still empty, try adding some!
                </div>
              </b-alert>
            </div>
          </div> 
        </b-col>
      </b-row>

      <div v-for="(field, index) in fieldGroup.fields" :key="index" class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Field Name"
              label-for="field-name"
            >
              <b-form-input
                id="field-name"
                v-model="field.name"
                placeholder="Field name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Types"
              label-for="field-type"
            >
              <div class="row">
                <div class="col-8">
                  <v-select
                    v-model="field.type"
                    :options="types"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="field-type"
                  />

                </div>
                <div class="col-4">
                  <b-button variant="warning" @click="viewOptions(index)" :disabled="field.type != 'options'"> <feather-icon
                      icon="ListIcon"
                    /></b-button>
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Placeholder"
              label-for="field-placeholder"
            >
              <b-form-input
                id="field-placeholder"
                v-model="field.placeholder"
                placeholder="Field placeholder"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <div class="float-left">
              <b-form-checkbox
                v-model="field.required"
                v-bind:value="true"
                v-bind:unchecked-value="false"
                class="mt-2"
                style="top: 8px; position: relative;"
              >
                Required
              </b-form-checkbox>
            </div>

            <div class="float-left ml-2">
              <b-button class="mt-2" variant="danger" @click="removeField(index)">
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
            </div>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="9">
            <b-form-group
              label="Additional Content"
              label-for="additional-content"
            >
              <b-overlay :show="field.isLoading" rounded="sm">
                <vue-editor 
                  v-model="field.additional_content" 
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  @focus="currentFieldIndex = index"
                  :id="`field-${index}`"
                  :aria-hidden="field.isLoading ? 'true' : null"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-form>

    <!-- Action Buttons -->
    <div class="mt-2">
      <div style="float: left">
        <b-button
          variant="primary"
          @click="save()"
          :disabled="!isValid() || isLoading"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span>{{ (isLoading) ? "Loading.." : "Save" }}</span>
        </b-button>
      </div>

      <div style="float: right">
        <b-button size="md" variant="outline-primary" @click="back()">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Back</span>
        </b-button>
      </div>
    </div>

    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showOptionsModal"
    >
      <template #modal-header="{}">
        <h5>Field Options</h5>
      </template>

      <b-form>
          <b-button @click="addOption()" variant="primary" class="mb-2" size="sm">
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span>Add new options</span>
          </b-button>
        <div>
          <b-row v-for="(option, index) in currentFieldOptions.options" :key="index" class="mb-1">
            <b-col cols="6">
              <b-form-input
                name="text"
                v-model="currentFieldOptions.options[index]"
                placeholder="Option value"
              />
            </b-col>
            <b-col cols="2">
              <b-button variant="danger" @click="removeOption(index)">
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-primary" @click="saveOptions(currentFieldOptions.index)"  :disabled="currentFieldOptions.options.length < 2">
          Save
        </b-button>
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
      </template>


    </b-modal>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BInputGroup, BInputGroupAppend, BAlert, BModal, BSpinner, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueEditor } from "vue2-editor";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BModal,
    VueEditor,
    BSpinner,
    BOverlay
  },
  props: {
    fieldGroupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentFieldIndex: 0,
      isLoading: false,
      showOptionsModal: false,
      currentFieldOptions: {
        name: '',
        options: [],
        index: -1
      },
      fieldGroup: {
        id: null,
        name: '',
        fields: []
      },
      types: [
        {
          label: "Text",
          value: "text"
        },
        {
          label: "Choices",
          value: "options"
        }
      ]
    }
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.isLoading = true

      this.fieldGroup.fields[this.currentFieldIndex].isLoading = true

      const requestData = new FormData()

      requestData.append('image', file);

      this.$http.post('/admin/v1/upload_image', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const data = res.data
              const currentData = data.data

              const url = currentData.url

              this.isLoadingUpload = false

              Editor.insertEmbed(cursorLocation, "image", url)
              
              resetUploader()

            }
            this.fieldGroup.fields[this.currentFieldIndex].isLoading = false

            this.isLoading = false
        })

    },
    back() {
        this.$router.push('/master/fields')
    },
    saveOptions(fieldIndex) {
      this.fieldGroup.fields[fieldIndex].attributes.options = []

      this.currentFieldOptions.options.forEach((item) => {
        this.fieldGroup.fields[fieldIndex].attributes.options.push(item)
      })

      this.showOptionsModal = false
    },
    viewOptions(index) {
      this.showOptionsModal = true

      this.currentFieldOptions.index = index
      this.currentFieldOptions.name = this.fieldGroup.fields[index].name
      this.currentFieldOptions.options = []

      if(this.fieldGroup.fields[index].attributes.options !== null) {
        this.fieldGroup.fields[index].attributes.options.forEach((value) => {
          this.currentFieldOptions.options.push(value)
        })

        if(this.currentFieldOptions.options.length == 0) {
          this.currentFieldOptions.options = [""]
        }
      }
      else {
        this.currentFieldOptions.options = [""]
      }
    },
    removeField(index) {
      this.fieldGroup.fields.splice(index, 1)
    },
    removeOption(optionIndex) {
      this.currentFieldOptions.options.splice(optionIndex, 1)
    },
    addOption() {
      if(this.currentFieldOptions.options === null) {
        this.currentFieldOptions.options = []  
      }

      this.currentFieldOptions.options.push("")
    },
    addField() {
      this.fieldGroup.fields.push({
          name: null,
          additional_content: null,
          type: 'text',
          placeholder: null,
          attributes: {
            options: null,
            preset_options: null,
          },
          required: true,
          id: -1,
          isLoading: false
        })
    },
    save() {
      this.isLoading = true

      const requestData = {
        name: this.fieldGroup.name,
        fields: this.fieldGroup.fields
      }

      if(this.fieldGroup.id !== null && this.fieldGroup.id != -1) {
        this.$http.put('/admin/v1/field_groups/' + this.fieldGroup.id, requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

            }

            this.isLoading = false
        })
      }
      else {
        this.$http.post('/admin/v1/field_groups', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger', 
                  text: message,
                },
              })
            }
            else {
              this.$router.replace("/master/fields")
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: message,
                    },
                  })
                })
            }

            this.isLoading = false
        })
      }
    },
    fetchData() {
      if(this.fieldGroup.id < 0) {
        return
      }

      this.isLoading = true

      this.$http.get('/admin/v1/field_groups/' + this.fieldGroup.id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.fieldGroup.name = currentData.name

            currentData.fields.forEach((item) => {
              item.isLoading = false

              this.fieldGroup.fields.push(item)
            })
          }

          this.isLoading = false
       })
    },
    isValid() {
      let fieldValid = true

      this.fieldGroup.fields.forEach((currentField) => {
        if(currentField.name === null || currentField.name == "" || currentField.type === null || currentField.type == "") {
          fieldValid = false
        }
      } )

      return this.fieldGroup.name != "" && this.fieldGroup.fields.length > 0 && fieldValid
    }
  },
  created() {
    this.fieldGroup.id = parseInt(this.fieldGroupId)

    if(this.fieldGroup.id != -1) {
      this.fetchData()
    }
    else {
      this.addField()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
